<template>
    <span class="rank-info" :style="{'backgroundColor': rankInfo.color}">{{ rankInfo.text }}</span>
</template>

<script>
    // 课程等级
    const rank = {
        1: {
            text: '初级',
            color: '#d9bd95'
        },
        2: {
            text: '中级',
            color: '#cf9c41'
        },
        3: {
            text: '高级',
            color: '#ef6e1b'
        }
    }

    export default {
        name: 'rank',
        props: {
            rank: {
                type: Number,
                default: 1
            }
        },
        computed: {
            rankInfo () {
                return rank[this.rank]
            }
        }
    }
</script>

<style lang="less" scoped>
    .rank-info {
        padding: 2px 8px;
        font-size: 10px;
        color: #FFF;
        border-radius: 10px;
    }
</style>