<template>
    <div class="train">
        <header class="train-header">
            <h1>FHIR标准培训与实施指导</h1>
        </header>
        <div class="content">
            <img class="content-banner" :src="data.img" alt="" />
            <div class="content-box">
                <div class="header">
                    <h1>{{ data.title }}</h1>
                </div>
                <div class="courses">
                    <ul class="course-box">
                        <li v-for="(item, i) in data.courses" :key="i">
                            <div class="header-item">
                                <h2>{{ item.title }}</h2>
                                <rank-info :rank="item.rank"/>
                            </div>
                            <div class="course-content">
                                <p class="learn">学习内容:</p>
                                <p class="learn-content">{{ item.content }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="course-list-box">
                    <ul class="course-list">
                        <li v-for="(item, i) in data.courseList" :key="i">
                            <span>{{ item.title }}</span>
                            <rank-info :rank="item.rank"/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // FHIR标准培训与实施指导
    // 组件
    import RankInfo from './rank';

    // API
    import { getTrain } from '@/api/request';

    // vuex
    import { mapMutations } from 'vuex';
    
    export default {
        name: 'train',
        created () {
            this.getTrainAjax();
        },
        data () {
            return {
                data: ''
            }
        },
        methods: {
            ...mapMutations([
                'setBusinessBanners'
            ]),
            getTrainAjax () {
                getTrain().then(res => {
                    this.setBusinessBanners(res.banners);
                    this.data = res.data;
                })
            }
        },
        components: {
            RankInfo
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .train {
        .train-header {
            padding: 20px 40px;
            border-bottom: 1px solid #ccc;
            h1 {
                font-size: 22px;
                font-weight: bold;
            }
        }
        .content {
            .content-box {
                padding: 50px 40px;
                .header {
                    text-align: center;
                    h1 {
                        display: inline-block;
                        margin: 0 auto;
                        padding: 4px 30px;
                        font-size: 30px;
                        color: #FFF;
                        background-color: @theme-color;
                    }
                }
                .courses {
                    .course-box {
                        display: flex;
                        margin-top: 60px;
                        li {
                            flex: 1;
                            margin-right: 20px;
                            padding: 20px 12px;
                            font-size: 14px;
                            color: #777;
                            border: 1px solid #ccc;
                            &:last-child {
                                margin-right: 0;
                            }
                            .header-item {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                h2 {
                                    color: #000;
                                    font-size: 16px;
                                    font-weight: bold;
                                }
                            }
                            .course-content {
                                margin-top: 20px;
                                .learn {
                                    margin-bottom: 4px;
                                }
                                .learn-content {
                                    line-height: 1.5;
                                }
                            }
                        }
                    }
                }
                .course-list-box {
                    .course-list {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 30px;
                        li {
                            display: flex;
                            justify-content: space-between;
                            padding: 10px 20px;
                            margin-bottom: 6px;
                            margin-right: 10px;
                            width: calc(~'50% - 5px');
                            background-color: #f2f2f2;
                            box-shadow: 0 0 1px #999;
                            &:nth-child(2n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .train {
            .train-header {
                padding: 0.26666rem 0.53333rem;
                h1 {
                    font-size: 0.48rem;
                }
            }
            .content {
                .content-banner {
                    width: 100%;
                }
                .content-box {
                    padding: 0.8rem 0.5333rem;
                    .header {
                        h1 {
                            font-size: 0.53333rem;
                        }
                    }
                    .courses {
                        .course-box {
                            flex-direction: column;
                            margin-top: 1.0666rem;
                            li {
                                margin-bottom: 0.32rem;
                                margin-right: 0;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .train {
            .content {
                .content-box {
                    .course-list-box {
                        .course-list {
                            flex-direction: column;
                            li {
                                margin: 0.16rem 0 0 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
</style>